import { firestoreDb } from '@/database'

import {

	addDoc,
	arrayRemove,
	arrayUnion,
	collection,
	deleteDoc,
	deleteField,
	doc,
	endAt,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	startAfter,
	startAt,
	updateDoc,
	where,
	Timestamp
} from 'firebase/firestore'

const USERS_PATH = 'users'
const ROOMS_PATH = 'chatRooms'
const MESSAGES_PATH = 'messages'
const MESSAGE_PATH = roomId => {
	return `${ROOMS_PATH}/${roomId}/${MESSAGES_PATH}`
}

const TIMESTAMP_FIELD = 'timestamp'
const LAST_UPDATED_FIELD = 'lastUpdated'
const TYPING_USERS_FIELD = 'typingUsers'
const MESSAGE_REACTIONS_FIELD = 'reactions'
const ROOM_USERS_FIELD = 'users'
const TEAM_TYPE = 'teamType'
const CHAT_TYPE ='chatType'

export const firestoreListener = onSnapshot
export const deleteDbField = deleteField()

const getDocuments = query => {
	return getDocs(query).then(docs => {
		return { data: formatQueryDataArray(docs), docs: docs.docs }
	})
}

const getDocument = ref => {
	return getDoc(ref).then(doc => formatQueryDataObject(doc))
}

const addDocument = (ref, data) => {
	return addDoc(ref, data)
}

const setDocument = (path, docId, data) => {
	return setDoc(doc(firestoreDb, path, docId), data)
}

const updateDocument = (ref, data) => {
	return updateDoc(ref, data)
}

const deleteDocument = (ref, docId) => {
	return deleteDoc(doc(firestoreDb, ref, docId))
}

// USERS
const usersRef = collection(firestoreDb, USERS_PATH)

const userRef = userId => {
	return doc(firestoreDb, USERS_PATH, userId)
}

export const getAllUsers = () => {
	return getDocuments(query(usersRef))
}

export const getUser = userId => {
	return getDocument(userRef(userId))
}

export const getUserCid = userId => {
	return getDocuments(
		query(usersRef, where('c_id', '==', userId))
	)
}

export const getUserFirebaseID = userId => {
	return getDocuments(
		query(usersRef, where('_id', '==', userId))
	)
}

export const addUser = data => {
	return addDocument(usersRef, data)
}

export const addIdentifiedUser = (userId, data) => {
	return setDocument(USERS_PATH, userId, data)
}

export const updateUser = (userId, data) => {
	return updateDocument(userRef(userId), data)
}

export const deleteUser = userId => {
	return deleteDocument(USERS_PATH, userId)
}

// ROOMS
const roomsRef = collection(firestoreDb, ROOMS_PATH)

const roomRef = roomId => {
	return doc(firestoreDb, ROOMS_PATH, roomId)
}


export const getRoomById = roomId => {
	return getDocument(roomRef(roomId))
}




export const roomsQuery = (currentUserId, roomsPerPage, lastRoom, teamsFilters = "", chatTypeCheck) => {
	
	let baseQuery;
	if (chatTypeCheck) {
		baseQuery = query(
		roomsRef,
		where(USERS_PATH, 'array-contains', currentUserId),
		where(CHAT_TYPE, '==', chatTypeCheck),
		orderBy(LAST_UPDATED_FIELD, 'desc'),
		limit(roomsPerPage)
		);
	} else {
		baseQuery = query(
		roomsRef,
		where(USERS_PATH, 'array-contains', currentUserId),
		orderBy(LAST_UPDATED_FIELD, 'desc'),
		limit(roomsPerPage)
		);
	}

	if (lastRoom) {
		return query(
			baseQuery,
			startAfter(lastRoom)
		)
	}else if(teamsFilters != ""){
		chatTypeCheck='team';
		if(teamsFilters.team_type && teamsFilters.chat_year && teamsFilters.sportTypeFilter){
			const startOfYear = new Date(teamsFilters.chat_year+'-01-01');
			const endOfYear = new Date(teamsFilters.chat_year+'-12-31');
		return query(
			roomsRef,
			where(USERS_PATH, 'array-contains', currentUserId),
			where(CHAT_TYPE, '==', chatTypeCheck),
			where('teamType', '==', teamsFilters.team_type),
			where('createdAtTimestamp', '>=', startOfYear),
			where('createdAtTimestamp', '<=', endOfYear),
			where('sportType', '==', teamsFilters.sportTypeFilter),
			orderBy('createdAtTimestamp', 'desc'),
			orderBy(LAST_UPDATED_FIELD, 'desc'),
			limit(roomsPerPage)
		)
		}
		else if(teamsFilters.team_type  && teamsFilters.chat_year){
			const startOfYear = new Date(teamsFilters.chat_year+'-01-01');
			const endOfYear = new Date(teamsFilters.chat_year+'-12-31');
		return query(
			roomsRef,
			where(USERS_PATH, 'array-contains', currentUserId),
			where(CHAT_TYPE, '==', chatTypeCheck),
			where('teamType', '==', teamsFilters.team_type),
			where('createdAtTimestamp', '>=', startOfYear),
			where('createdAtTimestamp', '<=', endOfYear),
			orderBy('createdAtTimestamp', 'desc'),
			orderBy(LAST_UPDATED_FIELD, 'desc'),
			limit(roomsPerPage)
		)
		}
		else if(teamsFilters.chat_year && teamsFilters.sportTypeFilter){
			const startOfYear = new Date(teamsFilters.chat_year+'-01-01');
			const endOfYear = new Date(teamsFilters.chat_year+'-12-31');
		return query(
			roomsRef,
			where(USERS_PATH, 'array-contains', currentUserId),
			where(CHAT_TYPE, '==', chatTypeCheck),
			where('sportType', '==', teamsFilters.sportTypeFilter),
			where('createdAtTimestamp', '>=', startOfYear),
			where('createdAtTimestamp', '<=', endOfYear),
			orderBy('createdAtTimestamp', 'desc'),
			orderBy(LAST_UPDATED_FIELD, 'desc'),
			limit(roomsPerPage)
		)
		}
		else if(teamsFilters.team_type  && teamsFilters.sportTypeFilter){
		return query(
			roomsRef,
			where(USERS_PATH, 'array-contains', currentUserId),
			where(CHAT_TYPE, '==', chatTypeCheck),
			where('teamType', '==', teamsFilters.team_type),
			where('sportType', '==', teamsFilters.sportTypeFilter),
			orderBy(LAST_UPDATED_FIELD, 'desc'),
			limit(roomsPerPage)
		)
		}
		else if(teamsFilters.team_type){
		return query(
			roomsRef,
			where(USERS_PATH, 'array-contains', currentUserId),
			where(CHAT_TYPE, '==', chatTypeCheck),
			where('teamType', '==', teamsFilters.team_type),
			orderBy(LAST_UPDATED_FIELD, 'desc'),
			limit(roomsPerPage)
		)
		}else if(teamsFilters.chat_year){
			const startOfYear = new Date(teamsFilters.chat_year+'-01-01');
			const endOfYear = new Date(teamsFilters.chat_year+'-12-31');
			return query(
				roomsRef,
				where(USERS_PATH, 'array-contains', currentUserId),
				where(CHAT_TYPE, '==', chatTypeCheck),
				where('createdAtTimestamp', '>=', startOfYear),
				where('createdAtTimestamp', '<=', endOfYear),
				orderBy('createdAtTimestamp', 'desc'),
				orderBy(LAST_UPDATED_FIELD, 'desc'),
				limit(roomsPerPage)
			)

		}
		else if(teamsFilters.sportTypeFilter){
			return query(
				roomsRef,
				where(USERS_PATH, 'array-contains', currentUserId),
				where(CHAT_TYPE, '==', chatTypeCheck),
				where('sportType', '==', teamsFilters.sportTypeFilter),
				orderBy(LAST_UPDATED_FIELD, 'desc'),
				limit(roomsPerPage)
			)

		}
		else{
			return query(
				roomsRef,
				where(USERS_PATH, 'array-contains', currentUserId),
				where(CHAT_TYPE, '==', chatTypeCheck),
				orderBy(LAST_UPDATED_FIELD, 'desc'),
				limit(roomsPerPage)
			)
		}
	}
	 else {
		return query(
			baseQuery
		)
	}

}

export const adminTeamsQuery = async (currentUserId, roomsPerPage, lastRoom) => {
	const chatTypeCheck = 'team'; // Set chat type to team

	// Base query to fetch rooms with the current user and chat type 'team'
	let baseQuery = query(
		roomsRef,
		where(USERS_PATH, 'array-contains', currentUserId),
		where(CHAT_TYPE, '==', chatTypeCheck),
		orderBy(LAST_UPDATED_FIELD, 'desc'),
		limit(roomsPerPage)
	);

	// If lastRoom is provided, use startAfter for pagination
	if (lastRoom) {
		baseQuery = query(baseQuery, startAfter(lastRoom));
	}

	// Execute the query and get the results
	const querySnapshot = await getDocs(baseQuery);
	const teams = querySnapshot.docs.map(doc => doc.data());

	// Filter teams based on userRole and currentUserId
	const filteredTeams = teams.filter(team => 
		team.userRole.some(role => role.id === currentUserId && role.admin === "1")
	);

	// Add count and teamNames to the filteredTeams array
	const result = {
		teams: filteredTeams,
		count: filteredTeams.length, // Add count of filtered teams
		teamNames: filteredTeams.map(team => team.teamName) // Add array of team names
	};

	// Log the final result
	console.log("Filtered Teams with Count and Team Names:", result);

	// Return the modified result
	return result;
};


export const roomsSportQuery = (currentUserId) => {
	return query(
		roomsRef,
		where(USERS_PATH, 'array-contains', currentUserId),
		// where(TEAM_TYPE, "==", "sports"),
		where(CHAT_TYPE, "==", "team"),
	)
}

export const roomsGameTimeQuery = (currentUserId) => {
	return query(
		roomsRef,
		where(USERS_PATH, 'array-contains', currentUserId),
		where(TEAM_TYPE, "==", "sports"),
		where(CHAT_TYPE, "==", "team")
	)
}

export const allRooms = (currentUserId) => {
	return query(
		roomsRef,
		where(USERS_PATH, 'array-contains', currentUserId)
	)
}

export const countRoomsForUser = async (currentUserId,chatTypeCheck) => {
	  const q = query(
		roomsRef,
		where(USERS_PATH, 'array-contains', currentUserId),
		where(CHAT_TYPE, '==', chatTypeCheck),
	  );
  
	  const querySnapshot = await getDocs(q);
	  const count = querySnapshot.size;
  
	  return count;
  };
  
export const getExistsRoom = async (currentUserId, sentUserId) => {
  try {
    const query1 = query(
      roomsRef,
      where('users', 'array-contains', currentUserId),
      where(CHAT_TYPE, '==', 'individual')
    );

    const query2 = query(
      roomsRef,
      where('users', 'array-contains', sentUserId),
      where(CHAT_TYPE, '==', 'individual')
    );

    const [querySnapshot1, querySnapshot2] = await Promise.all([
      getDocs(query1),
      getDocs(query2),
    ]);

    const matchingRooms1 = [];
    querySnapshot1.forEach((doc) => {
      matchingRooms1.push(doc.id);
    });

    const matchingRooms2 = [];
    querySnapshot2.forEach((doc) => {
      matchingRooms2.push(doc.id);
    });
    const commonRooms = matchingRooms1.filter((roomId) =>
      matchingRooms2.includes(roomId)
    );

    return commonRooms;
  } catch (error) {
    console.error('Error fetching chat rooms:', error);
    throw error;
  }
};

export const getAllRooms = () => {
	return getDocuments(query(roomsRef))
}

export const getRooms = query => {
	return getDocuments(query)
}

export const addRoom = data => {
	return addDocument(roomsRef, data)
}

export const updateRoom = (roomId, data) => {
	return updateDocument(roomRef(roomId), data)
}

export const deleteRoom = roomId => {
	return deleteDocument(ROOMS_PATH, roomId)
}

export const getUserRooms = (currentUserId, userId) => {
	return getDocuments(
		query(roomsRef, where(USERS_PATH, '==', [currentUserId, userId]))
	)
}

export const addRoomUser = (roomId, userId) => {
	return updateRoom(roomId, {
		[ROOM_USERS_FIELD]: arrayUnion(userId)
	})
}

export const removeRoomUser = (roomId, userId) => {
	return updateRoom(roomId, {
		[ROOM_USERS_FIELD]: arrayRemove(userId)
	})
}

export const updateRoomTypingUsers = (roomId, currentUserId, action) => {
	const arrayUpdate =
		action === 'add' ? arrayUnion(currentUserId) : arrayRemove(currentUserId)

	return updateRoom(roomId, { [TYPING_USERS_FIELD]: arrayUpdate })
}

// MESSAGES
const messagesRef = roomId => {
	return collection(firestoreDb, MESSAGE_PATH(roomId))
}

const messageRef = (roomId, messageId) => {
	return doc(firestoreDb, MESSAGE_PATH(roomId), messageId)
}

export const getMessages = (roomId, messagesPerPage, lastLoadedMessage) => {

	if (lastLoadedMessage) {
		return getDocuments(
			query(
				messagesRef(roomId),
				orderBy(TIMESTAMP_FIELD, 'desc'),
				limit(messagesPerPage),
				startAfter(lastLoadedMessage)
			)
		)
	} else if (messagesPerPage) {
		return getDocuments(
			query(
				messagesRef(roomId),
				orderBy(TIMESTAMP_FIELD, 'desc'),
				limit(messagesPerPage)
			)
		)
	} else {
		return getDocuments(messagesRef(roomId))
	}
}
export const getMessagesToUpdate = (roomId) => {
		return getDocuments(messagesRef(roomId))
}
export const getMediaMessages = (roomId, messagesPerPage, lastLoadedMessage) => {
	if (lastLoadedMessage) {
		return getDocuments(
			query(
				messagesRef(roomId),
				where("hasFile", "==", true),
				orderBy(TIMESTAMP_FIELD, 'desc'),
				limit(messagesPerPage),
				startAfter(lastLoadedMessage)
			)
		)
	} else if (messagesPerPage) {
		return getDocuments(
			query(
				messagesRef(roomId),
				where("hasFile", "==", true),
				orderBy(TIMESTAMP_FIELD, 'desc'),
				limit(messagesPerPage)
			)
		)
	} else {
		return getDocuments(messagesRef(roomId))
	}
}
export const  getPinnedMediaMessages = (roomId, messagesPerPage, lastLoadedMessage, filter) => {
	if(filter){
		
		if(filter.selectedEvent && filter.startDate && filter.tags && filter.name){
			const date = new Date(filter.startDate);
			const startOfDay = new Date(date);
			startOfDay.setHours(0, 0, 0, 0);
			const endOfDay = new Date(date);
			endOfDay.setHours(23, 59, 59, 999);
			const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
  			const endOfDayTimestamp = Timestamp.fromDate(endOfDay);
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.selectedEvent && filter.startDate && filter.name){
			const date = new Date(filter.startDate);
			const startOfDay = new Date(date);
			startOfDay.setHours(0, 0, 0, 0);
			const endOfDay = new Date(date);
			endOfDay.setHours(23, 59, 59, 999);
			const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
  			const endOfDayTimestamp = Timestamp.fromDate(endOfDay);
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.name", '==', filter.name),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.name", '==', filter.name),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.name", '==', filter.name),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.selectedEvent && filter.startDate && filter.tags){
			const date = new Date(filter.startDate);
			const startOfDay = new Date(date);
			startOfDay.setHours(0, 0, 0, 0);
			const endOfDay = new Date(date);
			endOfDay.setHours(23, 59, 59, 999);
			const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
  			const endOfDayTimestamp = Timestamp.fromDate(endOfDay);
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.selectedEvent && filter.tags && filter.name){
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.startDate && filter.tags && filter.name){
			const date = new Date(filter.startDate);
			const startOfDay = new Date(date);
			startOfDay.setHours(0, 0, 0, 0);
			const endOfDay = new Date(date);
			endOfDay.setHours(23, 59, 59, 999);
			const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
  			const endOfDayTimestamp = Timestamp.fromDate(endOfDay);
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.selectedEvent && filter.startDate){
			const date = new Date(filter.startDate);
			const startOfDay = new Date(date);
			startOfDay.setHours(0, 0, 0, 0);
			const endOfDay = new Date(date);
			endOfDay.setHours(23, 59, 59, 999);
			const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
  			const endOfDayTimestamp = Timestamp.fromDate(endOfDay);
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.selectedEvent && filter.tags){
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.selectedEvent && filter.name){
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.startDate && filter.tags){
			const date = new Date(filter.startDate);
			const startOfDay = new Date(date);
			startOfDay.setHours(0, 0, 0, 0);
			const endOfDay = new Date(date);
			endOfDay.setHours(23, 59, 59, 999);
			const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
  			const endOfDayTimestamp = Timestamp.fromDate(endOfDay);
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.tags && filter.name){
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.startDate && filter.name){
			const date = new Date(filter.startDate);
			const startOfDay = new Date(date);
			startOfDay.setHours(0, 0, 0, 0);
			const endOfDay = new Date(date);
			endOfDay.setHours(23, 59, 59, 999);
			const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
  			const endOfDayTimestamp = Timestamp.fromDate(endOfDay);
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.selectedEvent){
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.eventId", "==", filter.selectedEvent.id),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.startDate){
			const date = new Date(filter.startDate);
			const startOfDay = new Date(date);
			startOfDay.setHours(0, 0, 0, 0);
			const endOfDay = new Date(date);
			endOfDay.setHours(23, 59, 59, 999);
			const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
  			const endOfDayTimestamp = Timestamp.fromDate(endOfDay);
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where(TIMESTAMP_FIELD, ">=", startOfDayTimestamp),
						where(TIMESTAMP_FIELD, "<=", endOfDayTimestamp),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.tags){
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.tags", 'array-contains', filter.tags),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.tags", 'array-contains', filter.tags ),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else if(filter.name){
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						where("tagsDetails.name", '==', filter.name),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
		else{
			if (lastLoadedMessage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage),
						startAfter(lastLoadedMessage)
					)
				)
			} else if (messagesPerPage) {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						orderBy(TIMESTAMP_FIELD, 'desc'),
						limit(messagesPerPage)
					)
				)
			} else {
				return getDocuments(
					query(
						messagesRef(roomId),
						where("pinFile", "==", true),
						orderBy(TIMESTAMP_FIELD, 'desc')
					)
				)
			}
		}
	}
	else{
		if (lastLoadedMessage) {
			return getDocuments(
				query(
					messagesRef(roomId),
					where("pinFile", "==", true),
					orderBy(TIMESTAMP_FIELD, 'desc'),
					limit(messagesPerPage),
					startAfter(lastLoadedMessage)
				)
			)
		} else if (messagesPerPage) {
			return getDocuments(
				query(
					messagesRef(roomId),
					where("pinFile", "==", true),
					orderBy(TIMESTAMP_FIELD, 'desc'),
					limit(messagesPerPage)
				)
			)
		} else {
			return getDocuments(
				query(
					messagesRef(roomId),
					where("pinFile", "==", true),
					orderBy(TIMESTAMP_FIELD, 'desc')
				)
			)
		}
	}
	
}

export const getMessage = (roomId, messageId) => {
	return getDocument(messageRef(roomId, messageId))
}

export const addMessage = (roomId, data) => {
	return addDocument(messagesRef(roomId), data)
}

export const updateMessage = (roomId, messageId, data) => {
	return updateDocument(messageRef(roomId, messageId), data)
}
export const updateMessageMedia = (roomId, messageId, data) => {
	return updateDocument(messageRef(roomId, messageId), data)
}

export const deleteMessage = (roomId, messageId) => {
	return deleteDocument(MESSAGE_PATH(roomId), messageId)
}

export const listenRooms = (query, callback) => {
	return firestoreListener(query, rooms => {
		callback(formatQueryDataArray(rooms))
	})
}

export const paginatedMessagesQuery = (
	roomId,
	lastLoadedMessage,
	previousLastLoadedMessage
) => {
	if (lastLoadedMessage && previousLastLoadedMessage) {
		return query(
			messagesRef(roomId),
			orderBy(TIMESTAMP_FIELD),
			startAt(lastLoadedMessage),
			endAt(previousLastLoadedMessage)
		)
	} else if (lastLoadedMessage) {
		return query(
			messagesRef(roomId),
			orderBy(TIMESTAMP_FIELD),
			startAt(lastLoadedMessage)
		)
	} else if (previousLastLoadedMessage) {
		return query(
			messagesRef(roomId),
			orderBy(TIMESTAMP_FIELD),
			endAt(previousLastLoadedMessage)
		)
	} else {
		return query(messagesRef(roomId), orderBy(TIMESTAMP_FIELD))
	}
}

export const listenMessages = (
	roomId,
	lastLoadedMessage,
	previousLastLoadedMessage,
	callback
) => {
	return firestoreListener(
		paginatedMessagesQuery(
			roomId,
			lastLoadedMessage,
			previousLastLoadedMessage
		),
		messages => {
			callback(formatQueryDataArray(messages))
		}
	)
}

const formatQueryDataObject = queryData => {
	return { ...queryData.data(), id: queryData.id }
}

const formatQueryDataArray = queryDataArray => {
	const formattedData = []

	queryDataArray.forEach(data => {
		formattedData.push(formatQueryDataObject(data))
	})
	return formattedData
}

const lastMessageQuery = roomId => {
	return query(messagesRef(roomId), orderBy(TIMESTAMP_FIELD, 'desc'), limit(1))
}

export const listenLastMessage = (roomId, callback) => {
	return firestoreListener(query(lastMessageQuery(roomId)), messages => {
		callback(formatQueryDataArray(messages))
	})
}

export const updateMessageReactions = (
	roomId,
	messageId,
	currentUserId,
	reactionUnicode,
	action
) => {
	const arrayUpdate =
		action === 'add' ? arrayUnion(currentUserId) : arrayRemove(currentUserId)

	return updateMessage(roomId, messageId, {
		[`${MESSAGE_REACTIONS_FIELD}.${reactionUnicode}`]: arrayUpdate
	})
}
